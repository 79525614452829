/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import { Typography, Box, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import Clock from "react-live-clock";
import ViewDayOutlined from "@mui/icons-material/ViewDayOutlined";
import LineAxisOutlined from "@mui/icons-material/LineAxisOutlined";

const SiteHeader = ({ metadata, setActive }) => {
  const [activeTab, setActiveTab] = useState("summary");

  const handleChange = (ev, tab) => {
    setActiveTab(tab);
    setActive(tab);
  };

  if (metadata) {
    return (
      <Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography className="dashboard-site" variant="h6" component="div">
            {metadata.site_name}
          </Typography>
          <Box minWidth="80px" display="flex" flexDirection="row" justifyContent="space-between">
            <Tabs
              orientation="horizontal"
              value={activeTab}
              onChange={handleChange}
              aria-label="site options nav"
              sx={{
                "& .MuiTabs-flexContainerVertical": {
                  alignItems: "center",
                },
                ".MuiTab-root": {
                  color: "#7f99c2",
                },
              }}
              TabIndicatorProps={{
                style: { background: "#7f99c2" },
              }}
              scrollButtons={false}
              centered={true}
            >
              <Tab
                disableRipple={true}
                icon={<ViewDayOutlined fontSize="small" />}
                value="summary"
                sx={{ minWidth: 0 }}
                label="Overview"
                iconPosition="start"
              />
              <Tab
                disableRipple={true}
                icon={<LineAxisOutlined fontSize="small" />}
                value="detail"
                sx={{ minWidth: 0 }}
                label="Grapher"
                iconPosition="start"
              />
            </Tabs>
          </Box>
        </Box>
        <div className="line"></div>
        <Box width="100%" display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="row">
            {metadata.address ? (
              <>
                <Box>
                  <Typography variant="body2" color="secondary">
                    {metadata.address.address1}, {metadata.address.city}, {metadata.address.state}{" "}
                    {metadata.address.zip}
                  </Typography>
                </Box>
                <Box ml="1em" mr="1em">
                  <Typography variant="body2" color="grey">
                    |
                  </Typography>
                </Box>
              </>
            ) : null}
            {metadata.latitude && metadata.longitude ? (
              <Box>
                <a
                  href={
                    "https://www.google.com/maps/search/?api=1&query=" +
                    metadata.latitude +
                    "," +
                    metadata.longitude
                  }
                  target="_new"
                >
                  <Typography variant="body2" color="secondary">
                    {metadata.latitude},{metadata.longitude}
                  </Typography>
                </a>
              </Box>
            ) : null}
          </Box>
          <Box display="flex" flexDirection="row">
            <Box mr="0.25em">
              <Typography variant="body2" color="grey">
                Time at site:
              </Typography>
            </Box>
            <Box mr="1em">
              <Typography variant="body2" color="secondary">
                <Clock format={"HH:mm:ss"} ticking={true} timezone={metadata.timezone} />
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="secondary">
                {metadata.timezone?.replaceAll("_", " ")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  } else {
    return null;
  }
};

SiteHeader.propTypes = {
  metadata: PropTypes.object,
  setActive: PropTypes.func,
};

export default SiteHeader;
