import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { SiteMetadata } from "../../../lib/Types";
import TimeSeriesLineGraph from "./TimeSeriesLineGraph";
import { GraphFavoriteData } from "../../../lib/Types";
import { useState, useEffect } from "react";
import SiteEquipmentList from "./../../Site/SiteEquipmentList";

const style = {
  modalBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "5px",
    padding: "2rem",
    minHeight: "150px",
    overflow: "auto",
  },
};
interface Props {
  site: SiteMetadata;
  isOpen: boolean;
  onClose: Function;
  graphFavorite: GraphFavoriteData;
}

export default function TimeSeriesLineGraphModal({ site, isOpen, onClose, graphFavorite }: Props) {
  const [selectedMetricUuids, setSelectedMetricUuids] = useState<string[]>([]);
  const [editModeEnabled, setEditModeEnabled] = useState<boolean>(false);

  const getCurrentSiteMetricUuids = () => {
    // graph favorite may contain multiple sites, so we need to filter for the current site
    const relevantSite = graphFavorite.sites.find(
      (favoriteSite) => favoriteSite.uuid === site.uuid,
    );
    return relevantSite?.metrics;
  };

  // we need metrics state for graph editing, but when the graph favorite changes the state should get overwritten
  const setMetricsFromGraphFavorite = () => {
    if (!graphFavorite) {
      return;
    }
    const graphFavoriteMetrics = getCurrentSiteMetricUuids();
    setSelectedMetricUuids(graphFavoriteMetrics || []);
  };

  useEffect(() => {
    setMetricsFromGraphFavorite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphFavorite]);

  if (!graphFavorite) {
    return null;
  }

  // Saved Graphs refetched outside this component after close, hence the handleCloseModal wrapper function.
  const handleCloseModal = () => {
    onClose();
    setEditModeEnabled(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style.modalBox}>
        <TimeSeriesLineGraph
          site={site}
          metricUuids={selectedMetricUuids}
          title={graphFavorite.title}
          isModal={true}
          graphFavorite={graphFavorite}
          handleCloseModal={handleCloseModal}
          key={graphFavorite.title}
          editModeEnabled={editModeEnabled}
          setEditModeEnabled={setEditModeEnabled}
          setMetricsFromGraphFavorite={setMetricsFromGraphFavorite}
        />
        {editModeEnabled && (
          <Box mt="2em">
            <SiteEquipmentList
              site={site}
              updateSelectedMetrics={setSelectedMetricUuids}
              selectedMetricUuids={selectedMetricUuids}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
}
