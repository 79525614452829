import React, { ReactNode } from "react";
import { SnackbarProvider, SnackbarKey } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface CustomSnackbarProviderProps {
  children: ReactNode;
}

const CustomSnackbarProvider: React.FC<CustomSnackbarProviderProps> = ({ children }) => {
  const notistackRef = React.useRef<SnackbarProvider>(null);

  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      action={(key: SnackbarKey) => (
        <IconButton aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
