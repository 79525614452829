/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState, useEffect } from "react";
import { Box, Popover } from "@mui/material";
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from "react-simple-maps";
import PropTypes from "prop-types";
import MapPopup from "./MapPopup";
import { getMarkerColor } from "../../lib/Util";

//TODO:  Get this info into the standard data model so that we can simply return it from the API
let staticMarkers = [
  { coordinates: [-74.11199675508533, 40.72325867291473], name: "Bowery" },
  { coordinates: [-73.19706443074509, 41.18777509680897], name: "Bridgeport" },
  { coordinates: [-79.86417228669276, 40.397469512903825], name: "Robotany" },
  {
    coordinates: [-75.86131709499614, 43.05351565950674],
    name: "Superior Metals",
  },
  {
    coordinates: [-122.59818387380193, 38.24244012013163],
    name: "Casa Grande High School",
  },
];

let underConstructionMarkers = [
  {
    name: "Gallaudet University",
    coordinates: [-76.99251627172046, 38.90839685286774],
  },
  {
    name: "Amond World Madera Phase 1",
    coordinates: [-120.10412631569557, 36.99478644121622],
  },
  {
    name: "Santa Margarita Water District",
    coordinates: [-117.63271278701319, 33.58128098228396],
  },
  {
    name: "Trinity Fruit Company",
    coordinates: [-119.45071033972751, 36.619147497208296],
  },
  { name: "Albertsons", coordinates: [-122.33740418487491, 37.91967373035351] },
  {
    name: "Goleta Water District",
    coordinates: [-119.8690285345668, 34.47106992879209],
  },
  { name: "Soboba", coordinates: [-116.93048529440482, 33.79160914052536] },
  { name: "QCD", coordinates: [-117.98782156370845, 34.030316733169016] },
  { name: "Verano", coordinates: [-78.65478095396044, 37.981278107479156] },
];

const markerSize = 6;

const SiteMap = ({ sites }) => {
  const [tooltipContent, setTooltipContent] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [scaleFactor, setScaleFactor] = useState(1);

  useEffect(() => {
    if (sites?.length > 0) {
      let newMarkers = [];
      sites.forEach((site) => {
        // Exclude sites with state "Pre-Commissioning"
        if (site.state === "Pre-Commissioning") {
          return;
        }

        let marker = {};
        marker.site = site;
        marker.name = site.name;
        marker.markerColor = getMarkerColor(site);

        if (site.latitude && site.longitude) {
          marker.coordinates = [site.longitude, site.latitude];
        } else {
          let staticMarker = staticMarkers.find((marker) => site.name === marker.name);
          if (staticMarker) {
            marker.coordinates = staticMarker.coordinates;
          }
        }

        if (marker.coordinates) {
          newMarkers.push(marker);
        }
      });
      setMarkers(newMarkers);
    }
  }, [sites]);

  const geoUrl = "/static/resources/usmap.json";

  return (
    <Box
      height="500px"
      ml="1em"
      mr="1em"
      className="sms-component"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Popover
        anchorEl={tooltipContent?.event.target}
        open={Boolean(tooltipContent)}
        onClose={() => setTooltipContent(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        container={Document.body}
      >
        {tooltipContent ? <MapPopup site={tooltipContent.site} /> : null}
      </Popover>
      <ComposableMap projection="geoAlbers">
        <ZoomableGroup
          center={[0, 0]}
          zoom={1}
          maxZoom={30}
          onMoveEnd={({ zoom }) => setScaleFactor(zoom)}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography key={geo.rsmKey} geography={geo} stroke="#7b9ab3" fill="#4b7597" />
              ))
            }
          </Geographies>
          {markers.map(({ site, coordinates, markerColor }) => (
            <Marker key={site?.name} coordinates={coordinates}>
              <circle
                r={markerSize / scaleFactor}
                fill={markerColor}
                stroke="#fff"
                strokeWidth={2 / scaleFactor}
                onClick={(ev) => {
                  setTooltipContent({ event: ev, site: site });
                }}
              />
            </Marker>
          ))}
          {underConstructionMarkers.map(({ name, coordinates }) => (
            <Marker key={name} coordinates={coordinates}>
              <circle
                r={markerSize / scaleFactor}
                fill={"grey"}
                stroke="#fff"
                strokeWidth={2 / scaleFactor}
                onClick={(ev) => {
                  setTooltipContent({
                    event: ev,
                    site: {
                      name: name,
                      power_data: null,
                      status: "Coming soon!",
                    },
                  });
                }}
              />
            </Marker>
          ))}
        </ZoomableGroup>
      </ComposableMap>
    </Box>
  );
};

SiteMap.propTypes = {
  sites: PropTypes.array,
};

export default SiteMap;
