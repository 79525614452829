import React from "react";
import { Tooltip as MuiTooltip, ToggleButton } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

interface Props {
  title: string;
  value: boolean;
  onChange: () => void;
  Icon: SvgIconComponent;
}

export const GraphToggle: React.FC<Props> = ({ title, value, onChange, Icon }) => (
  <MuiTooltip title={title}>
    <ToggleButton
      value={title}
      selected={value}
      onChange={onChange}
      id="tooltip-lock-legend"
      sx={{ p: "8.75px" }}
    >
      <Icon fontSize="inherit" sx={{ fontSize: "20px" }} />
    </ToggleButton>
  </MuiTooltip>
);

export default GraphToggle;
