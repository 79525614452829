import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "2rem",
  minHeight: "150px",
};

interface Props {
  saveGraph: Function;
  isOpen: boolean;
  setIsOpen: Function;
  isLoading: boolean;
}

export default function SaveGraphFavoriteModal({ saveGraph, isOpen, setIsOpen, isLoading }: Props) {
  const [graphName, setGraphName] = React.useState("");

  const handleClose = () => setIsOpen(false);
  const handleSaveGraph = async () => {
    try {
      await saveGraph(graphName);
      setGraphName("");
      setIsOpen(false);
    } catch (err) {
      console.log("Problem saving graph. ", err);
    }
  };
  const handleDontSave = () => {
    setGraphName("");
    handleClose();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" color="secondary">
            Save Graph to Site Overview
          </Typography>
          <TextField
            id="outlined-basic"
            label="Graph Name"
            variant="outlined"
            sx={{ mt: "3rem", width: "20rem" }}
            value={graphName}
            onChange={(e) => setGraphName(e.target.value)}
            inputProps={{ maxLength: 24 }}
          />
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "rgba(0, 0, 0, 0.6)" }}
            variant="subtitle2"
          >
            This will save the graph only for you. Date range will stay relative to the current
            date.
          </Typography>
          <Box sx={{ mt: "3rem", justifyContent: "flex-end", display: "flex" }}>
            <Button variant="outlined" color="inherit" onClick={handleDontSave}>
              Don’t Save
            </Button>
            <LoadingButton
              variant="contained"
              color="secondary"
              onClick={handleSaveGraph}
              sx={{ ml: ".5rem" }}
              loading={isLoading}
            >
              {isLoading ? "Saving..." : "Save Graph"}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
