import React, { useState } from "react";
import { Box } from "@mui/material";
import SiteEquipmentList from "./SiteEquipmentList";
import PropTypes from "prop-types";
import TimeSeriesLineGraph from "../Graphs/Common/TimeSeriesLineGraph";
import { useTheme } from "@mui/material/styles";
const SiteEquipmentDetail = ({ site }) => {
  const theme = useTheme();
  const [selectedMetricUuids, setSelectedMetricUuids] = useState([]);

  return (
    <Box display="flex" flexDirection="column" sx={theme.classes.genericPanel}>
      <Box>
        <TimeSeriesLineGraph site={site} metricUuids={selectedMetricUuids} />
      </Box>
      <Box mt="2em">
        <SiteEquipmentList
          site={site}
          updateSelectedMetrics={setSelectedMetricUuids}
          selectedMetricUuids={selectedMetricUuids}
        />
      </Box>
    </Box>
  );
};

SiteEquipmentDetail.propTypes = {
  site: PropTypes.object,
};

export default SiteEquipmentDetail;
