import React, { useEffect } from "react";

import { SiteMetadata } from "../../../lib/Types";
import { Typography, Box, Grid } from "@mui/material";
import { getGraphFavoritesQuery } from "../../../lib/Queries";
import { useQuery } from "@tanstack/react-query";
import { GraphFavoriteData } from "../../../lib/Types";
import { formatGraphFavoriteDateRange } from "../../../lib/GraphFavoritesUtil";
import LineAxisOutlined from "@mui/icons-material/LineAxisOutlined";

interface Props {
  site: SiteMetadata;
  handleShowGraphFavorites: Function;
  graphFavoritesIsOpen: boolean;
}

export default function GraphFavoriteList({
  site,
  handleShowGraphFavorites,
  graphFavoritesIsOpen,
}: Props) {
  const { data: graphFavorites, refetch } = useQuery({
    ...getGraphFavoritesQuery(),
  });

  // reload list of graph favorites after modal is closed in case graphs were deleted
  useEffect(() => {
    refetch();
  }, [graphFavoritesIsOpen]);

  const styles = {
    graphFavoriteCard: {
      backgroundColor: "#fff",
      borderRadius: "5px",
      boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
      padding: "1.5rem",
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        boxShadow: "0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        cursor: "pointer",
      },
    },
  };

  const getSiteGraphFavorites = () => {
    // graph favorites contain multiple sites, so we need to filter
    if (!graphFavorites) return null;
    return graphFavorites?.filter((graphFavorite) => {
      const graphSiteUuids: string[] = graphFavorite.sites.map((graphSite) => graphSite.uuid);
      return graphSiteUuids.includes(site.uuid);
    });
  };

  const getGraphFavoriteCard = (graphFavorite: GraphFavoriteData) => {
    return (
      <Grid item sm={4}>
        <Box sx={styles.graphFavoriteCard} onClick={() => handleShowGraphFavorites(graphFavorite)}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" color="secondary">
              {graphFavorite.title}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {formatGraphFavoriteDateRange(graphFavorite, site.timezone)}
            </Typography>
          </Box>
          <Box sx={{ color: "rgba(0, 0, 0, 0.56)" }}>
            <LineAxisOutlined fontSize="small" color="inherit" />
          </Box>
        </Box>
      </Grid>
    );
  };

  if (!getSiteGraphFavorites()) return null;

  return (
    <Box>
      <Typography variant="h5" color="secondary" sx={{ mt: "3rem", mb: "1.5rem" }}>
        Saved Graphs
      </Typography>
      <Grid container spacing={3}>
        {getSiteGraphFavorites()?.map((graphFavorite) => {
          return getGraphFavoriteCard(graphFavorite);
        })}
      </Grid>
    </Box>
  );
}
