import React from "react";
import { Tooltip as MuiTooltip, ToggleButton, Box, Button } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { useState } from "react";
import { GraphRange, SiteMetadata } from "./../../../lib/Types";
import DownloadDialog from "./DownloadDialog";
import AlarmsDownloadDialog from "./AlarmsDownloadDialog";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Menu, { MenuProps } from "@mui/material/Menu";
import { set } from "immutable";

interface Props {
  displayRange: GraphRange;
  site: SiteMetadata;
  timezone: string;
  metricsUuids?: string[] | undefined;
}

const GraphDownloadMenu: React.FC<Props> = ({ displayRange, site, timezone, metricsUuids }) => {
  const [powerDownloadOpen, setPowerDownloadOpen] = useState(false);
  const [alarmsDownloadOpen, setAlarmsDownloadOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuIsOpen(true);
  };

  const getMenu = () => (
    <Box>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        open={menuIsOpen}
        onClose={() => setMenuIsOpen(false)}
        anchorEl={anchorEl}
      >
        <MenuItem
          onClick={() => {
            setPowerDownloadOpen(true);
          }}
          disableRipple
          sx={{ fontSize: "13px" }}
        >
          Graph Data (CSV)
        </MenuItem>
        {!metricsUuids && (
          <MenuItem
            onClick={() => {
              setAlarmsDownloadOpen(true);
            }}
            disableRipple
            sx={{ fontSize: "13px" }}
          >
            Alarms Data (CSV)
          </MenuItem>
        )}
      </Menu>
    </Box>
  );

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color={"inherit"}
        sx={{ color: "#0000008F", height: "40px" }}
      >
        Download
      </Button>
      {getMenu()}
      <DownloadDialog
        open={powerDownloadOpen}
        start={displayRange.start}
        stop={displayRange.stop}
        defaultInterval={displayRange.interval}
        onClose={() => setPowerDownloadOpen(false)}
        siteUuid={site.uuid}
        siteName={site.site_name}
        metricsUuids={metricsUuids}
        site={site}
      />
      {!metricsUuids && (
        <AlarmsDownloadDialog
          open={alarmsDownloadOpen}
          start={displayRange.start}
          stop={displayRange.stop}
          onClose={() => setAlarmsDownloadOpen(false)}
          siteUuid={site.uuid}
          siteName={site.site_name}
          timezone={timezone}
        />
      )}
    </Box>
  );
};

export default GraphDownloadMenu;
