import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "5px",
  padding: "2rem",
  minHeight: "150px",
};

interface Props {
  deleteGraph: Function;
  isOpen: boolean;
  setIsOpen: Function;
}

export default function GraphFavoriteDeleteModal({ deleteGraph, isOpen, setIsOpen }: Props) {
  const handleClose = () => setIsOpen(false);
  const handleDeleteGraph = async () => {
    try {
      await deleteGraph();
      setIsOpen(false);
    } catch (err) {
      console.log("Problem deleting graph. ", err);
    }
  };
  const handleDontSave = () => {
    handleClose();
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h4" color="secondary">
            Delete Saved Graph
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, color: "rgba(0, 0, 0, 0.6)" }}
            variant="subtitle2"
          >
            Your saved graph will be deleted. No data will be deleted.
          </Typography>
          <Box sx={{ mt: "3rem", justifyContent: "flex-end", display: "flex" }}>
            <Button variant="outlined" color="inherit" onClick={handleDontSave}>
              Don’t Delete
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteGraph}
              sx={{ ml: ".5rem" }}
            >
              {"Delete Graph"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
