/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import SortedTableHead from "./SortedTableHead";
import Row from "./Row";
import AlertBox from "../Shared/AlertBox";
import PropTypes from "prop-types";

/**
 * Validates if sorting will be ascendent or descendent
 *
 * @param {a}       Object of the array in the map loop
 * @param {b}       Next object of the array
 * @param {orderBy} Field to order
 */
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns the descending type
 *
 * @param {order}   asc or desc
 * @param {orderBy} Field to order
 */
export function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Creates a new array with sorted data
 *
 * @param {array}      List of sites
 * @param {comparator} Field to order
 */
export function stableSort(array, comparator) {
  const stabilizedThis = array.map((elem, index) => [elem, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((elem) => elem[0]);
}

const CollapsibleTable = ({ data }) => {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("name");
  const [alertData, setAlertData] = useState(null);

  /**
   * Updates the type of order and the field
   *
   * @param {event}
   * @param {property} Field to order
   */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  data.sites = data.sites.map((item) => {
    const total_power = item.power_data.total_power;

    // this count value is only used for internal sorting, so multiply high priority alarms
    // by 1000 so they always show up first.
    const count =
      item.alarms.find((alarm) => alarm.criticality === "high")?.count > 0
        ? item.alarms.find((alarm) => alarm.criticality === "high").count * 1000
        : item.alarms.find((alarm) => alarm.criticality === "medium").count;

    return {
      qtyAlarms: count,
      total_power: total_power,
      ...item,
    };
  });

  // if we have "data" here from React Query, we can assume it is fresh data and use it
  if (data) {
    return (
      <div className="container">
        <TableContainer>
          {alertData && (
            <AlertBox
              type={alertData.type}
              message={alertData.message}
              openAlert={alertData.openAlert}
              clearAlert={() => setAlertData(null)}
            />
          )}
          <Table stickyHeader={true} aria-label="sticky table">
            <SortedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {stableSort(data.sites, getComparator(order, orderBy))
                .filter((site) => site.state !== "Pre-Commissioning")
                .map((site, _id) => (
                  <Row key={site.name} row={site} setAlertData={setAlertData} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
};

CollapsibleTable.propTypes = {
  data: PropTypes.object,
};

export default CollapsibleTable;
