/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import React, { useState } from "react";
import { DataGridPro as DataGrid } from "@mui/x-data-grid-pro";
import { Button, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getOrganizationSitesListQuery } from "../../lib/Queries";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Shared/Loading";
import PropTypes from "prop-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import NewSiteModal from "./NewSiteModal";

const styles = {
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "288px",
    height: "36px",
  },
  buttonContainer: {
    marginRight: "20px",
    width: "184px",
  },
  searchInput: {
    paddingLeft: "12px",
    fontSize: 16,
    height: 40,
  },
};

export default function SitesTable({ onRowClick, OrganizationUuid }) {
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
  const [isNewSiteModalOpen, setIsNewSiteModalOpen] = useState(false);
  const {
    data: sitesList,
    isLoading: siteListIsLoading,
    refetch: refetchSiteList,
  } = useQuery({
    ...getOrganizationSitesListQuery(OrganizationUuid),
  });

  const isLoading = siteListIsLoading;

  const getFormattedLocation = (address) => {
    if (!address) return "";

    const { city, state } = address;
    let location = "";

    if (city) {
      location += city;
      if (state) {
        location += `, ${state}`;
      }
    } else if (state) {
      location += state;
    }

    return location;
  };

  const rows =
    !isLoading && sitesList.sites
      ? sitesList.sites.map((site) => ({
          id: site.uuid,
          name: site.site_name,
          uuid: site.uuid,
          state: site.state,
          utility: site.Utility,
          iso: site.iso,
          gas_utility: site.gas_utility,
          address: site.address || "",
          location: getFormattedLocation(site.address),
          timezone: site.timezone,
          latitude: site.latitude,
          longitude: site.longitude,
          send_notifications: site.send_notifications,
          contract_type: site.contract_type,
          deal_structure: site.deal_structure,
          bill_savings_provider: site.bill_savings_provider,
          grid_services_provider: site.grid_services_provider,
          commissioned_date: site.commissioned_date,
          contract_end_date: site.contract_end_date,
          date_acquired: site.date_acquired,
        }))
      : [];

  const getSortedRows = (rows, searchTerm) => {
    let filteredRows = rows.filter((row) =>
      row.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );

    filteredRows.sort((a, b) => {
      return a.name.localeCompare(b.name); // Default ascending order
    });

    return filteredRows;
  };

  const filteredRows = getSortedRows(rows, searchTerm);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (params) => {
    onRowClick(params.row);
  };

  const columns = [
    { field: "name", headerName: "Site Name", flex: 1 },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },

    {
      field: "action",
      headerName: "",
      flex: 0,
      align: "right",
      sortable: false,
      renderCell: (params) => <ChevronRightIcon />,
    },
  ];

  const handleNew = () => {
    setIsNewSiteModalOpen(true); // Open the new site modal
  };

  const handleModalClose = () => {
    setIsNewSiteModalOpen(false);
    refetchSiteList();
  };

  return (
    <>
      <div style={theme.classes.container}>
        <div style={styles.buttonContainer}>
          <Button variant="contained" sx={{ width: "100%" }} onClick={handleNew}>
            New Site
          </Button>
        </div>
        <NewSiteModal
          open={isNewSiteModalOpen}
          onClose={() => handleModalClose()}
          OrganizationUuid={OrganizationUuid}
        />
        <div style={styles.searchContainer}>
          <TextField
            id="search"
            label=""
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search sites"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: styles.searchInput,
            }}
            sx={{
              fontSize: 16,
            }}
          />
        </div>
      </div>
      {!isLoading && (
        <div style={theme.classes.tableContainer}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            autoHeight
            autoWidth
            disableSelectionOnClick
            sortingOrder={["asc", "desc"]}
            onRowClick={handleRowClick}
            hideFooter
            sx={{
              "&, .MuiDataGrid-columnHeader": {
                borderTop: "none",
              },
              "&, [class^=MuiDataGrid]": {
                overflowX: "hidden",
                borderLeft: "none",
                borderRight: "none",
              },
            }}
          />
        </div>
      )}
      {isLoading ? <Loading /> : null}
    </>
  );
}

SitesTable.propTypes = {
  OrganizationUuid: PropTypes.string.isRequired,
  onRowClick: PropTypes.func.isRequired,
};
