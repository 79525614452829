/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Typography, Button, useTheme } from "@mui/material";
import {
  getTimeSeriesMetricsQuery,
  getAlarmHistoryQuery,
  createGraphFavorite,
  editGraphFavorite,
  deleteGraphFavorite,
} from "../../../lib/Queries";
import {
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  ReferenceArea,
  ComposedChart,
} from "recharts";
import LineGraphTooltip from "./LineGraphTooltip";
import Loading from "../../Shared/Loading";
import DataComponentError from "../../Shared/DataComponentError";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import DateOptionPicker from "./DateOptionPicker";
import { decorateAlarms } from "../../../lib/Util";
import GraphUtil from "../../../lib/GraphUtil";
import AlarmTimeline from "./AlarmTimeline";
import CustomTick from "./CustomTick";
import CustomXAxis from "./CustomXAxis";
import { findSeries, findAssembly } from "../Logic/formatUtils";
import SaveGraphFavoriteModal from "./SaveGraphFavoriteModal";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  favoriteToGraphRange,
  graphRangeToFavoriteOffset,
} from "./../../../lib/GraphFavoritesUtil";
import GraphFavoritesDeleteModal from "./GraphFavoritesDeleteModal";
import ReadMoreOutlinedIcon from "@mui/icons-material/ReadMoreOutlined";
import ExpandOutlinedIcon from "@mui/icons-material/ExpandOutlined";
import PolylineOutlinedIcon from "@mui/icons-material/PolylineOutlined";
import GraphToggle from "./GraphToggle";
import GraphDownloadMenu from "./GraphDownloadMenu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSnackbar } from "notistack";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import TextField from "@mui/material/TextField";

const initializeGraphRange = (timezone, graphFavorite = null) => {
  if (graphFavorite) {
    return favoriteToGraphRange(graphFavorite, timezone);
  } else {
    return GraphUtil.getInitialGraphRange(timezone);
  }
};

const TimeSeriesLineGraph = ({
  site,
  metricUuids,
  isModal,
  title,
  graphFavorite,
  handleCloseModal,
  editModeEnabled,
  setEditModeEnabled,
  setMetricsFromGraphFavorite,
}) => {
  const theme = useTheme();

  const [timeSeries, setTimeSeries] = useState([]);
  const [timezone] = useState(site.timezone);
  const [timeseriesDataKeys, setTimeseriesDataKeys] = useState([]);
  const [animation, setAnimation] = useState(true);
  const [animationCount, setAnimationCount] = useState(0);
  const [bigBuckets, setBigBuckets] = useState([]);
  const [zoomParams, setZoomParams] = useState({ zoomed: false });
  const [preZoomDisplayRange, setPreZoomDisplayRange] = useState(null);
  const [axisUnits, setAxisUnits] = useState([]);
  const [useGraphInterpolation, setUseGraphInterpolation] = useState(
    graphFavorite ? graphFavorite.interpolation : true,
  );
  const [includeZeroYAxis, setIncludeZeroYAxis] = useState(
    graphFavorite ? graphFavorite.y_axis_adjust : false,
  );
  const [displayRange, setDisplayRange] = useState(initializeGraphRange(timezone, graphFavorite));
  const [graphPayload, setGraphPayload] = useState(null);
  const [lockLegend, setLockLegend] = useState(graphFavorite ? graphFavorite.lock_legend : false);
  const [isSaveGraphModalOpen, setIsSaveGraphModalOpen] = useState(false);
  const [isDeleteGraphModalOpen, setIsDeleteGraphModalOpen] = useState(false);
  const [editableGraphTitle, setEditableGraphTitle] = useState(title || "");

  const { enqueueSnackbar } = useSnackbar();

  // Some close graph logic located inside and outside of this component (outside=refresh Saved Graph list).  Hence the wrapper function.
  const handleGraphClose = () => {
    handleCloseModal();
    setEditModeEnabled(false);
    setMetricsFromGraphFavorite();
  };

  const handleGraphEditCancel = () => {
    setEditModeEnabled(false);
    setMetricsFromGraphFavorite();
    setEditableGraphTitle(title);
  };

  /**
   * Handles the onAnimationEnd event of the Area components.
   * Increments the animation count and checks if all animations are completed.
   * If all animations are completed, it sets the animation state to false.
   *
   * @returns {void}
   */
  const handleAnimationEnd = () => {
    setAnimationCount((prevCount) => prevCount + 1);
    if (animationCount >= 0) {
      setAnimation(false);
    }
  };

  const {
    isFetching,
    error,
    data: metricsTimeseriesData,
    refetch,
    isRefetchError,
  } = useQuery({
    ...getTimeSeriesMetricsQuery(
      site.uuid,
      displayRange.start,
      displayRange.stop,
      displayRange.interval,
      metricUuids,
    ),
    enabled: metricUuids?.length > 0,
    staleTime: 5000,
  });

  const { data: alarmHistoryData, refetch: refetchAlarms } = useQuery({
    ...getAlarmHistoryQuery(site.uuid, displayRange.start, displayRange.stop),
  });

  const createGraphFavoriteMutation = useMutation(createGraphFavorite);
  const deleteGraphFavoriteMutation = useMutation(deleteGraphFavorite);
  const editGraphFavoriteMutation = useMutation(editGraphFavorite);

  useEffect(() => {
    // decorate the alarms in the history with assemblies, if we have them.
    if (alarmHistoryData) {
      decorateAlarms(alarmHistoryData.historical_alerts, site.assemblies);
    }
  }, [alarmHistoryData]);

  useEffect(() => {
    refetch();
    refetchAlarms();
  }, [displayRange]);

  useEffect(() => {
    if (!metricUuids?.length) {
      setTimeSeries([]);
    }
  }, [metricUuids]);

  /** Keep timeSeriesDataKeys in same order as metrics.  Could possibly remove the need for this seperate list with a larger refactor */
  const sortTimeSeriesDataKeys = (keys, metricUuids) => {
    let sortedKeys = [];
    metricUuids.forEach((uuid) => {
      if (keys.includes(uuid)) {
        sortedKeys.push(uuid);
      }
    });
    return sortedKeys;
  };

  const seriesIsAllNull = (timeSeries, metricUuid) => {
    if (!timeSeries) {
      return true;
    }
    const nullRows = timeSeries.filter((row) => row[metricUuid] === null);
    const allNull = nullRows.length === timeSeries.length;
    return allNull;
  };

  const setupData = () => {
    if (!metricsTimeseriesData?.time_series?.length) {
      setTimeseriesDataKeys([]);
      setTimeSeries([]);
      return;
    }

    const rawTimeseriesDataKeys = Object.keys(metricsTimeseriesData.time_series[0]).filter(
      (key) => !key.endsWith("_min") && !key.endsWith("_max") && key !== "bucket",
    );
    // Make rawTimeseriesDataKeys match metricUuids order.  Using metricUuids instead of rawTimeseriesDataKeys currently causes errors.  Ideally these lists could be one in the same.
    const sortedTimeseriesDataKeys = sortTimeSeriesDataKeys(rawTimeseriesDataKeys, metricUuids);
    setTimeseriesDataKeys(sortedTimeseriesDataKeys);

    let seriesData = JSON.parse(JSON.stringify(metricsTimeseriesData.time_series));

    const { timeSeries: seriesDataWithBigBuckets, newBigBuckets } =
      GraphUtil.calculateBigBuckets(seriesData);

    setBigBuckets(newBigBuckets);

    // next, calculate the axes that we will need, based on the units of the series.
    const newAxes = [];
    sortedTimeseriesDataKeys.forEach((metric) => {
      let result = findSeries(metric, site);
      let tsMetric = result.tsMetric;
      if (tsMetric) {
        const axisId = tsMetric.unit;
        newAxes.push({ unit: tsMetric.unit, id: axisId });
      }
    });
    setAxisUnits(newAxes);
    setTimeSeries(seriesDataWithBigBuckets);
  };

  useEffect(() => {
    setupData();
  }, [metricsTimeseriesData]);

  const getBucketAlarms = GraphUtil.generateGetBucketAlarms(bigBuckets, alarmHistoryData);

  const cancelZoom = () => {
    setZoomParams({ zooming: false, zoomed: false });
    setDisplayRange(GraphUtil.updateDisplayRange(preZoomDisplayRange));
  };

  const styles = {
    missingDataContainer: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  };

  const singleYAxisWidth = 70;
  const getYAxes = () => {
    // reverse so axes appear in the correct order
    const reversedMetricUuids = [...timeseriesDataKeys].reverse();
    let usedUnits = [];
    const axes = [];
    reversedMetricUuids.forEach((metricUuid) => {
      let series_response = findSeries(metricUuid, site);
      let tsMetric = series_response.tsMetric;

      const allNull = seriesIsAllNull(timeSeries, metricUuid);
      const existingAxis = usedUnits.includes(tsMetric.unit);
      if (!tsMetric.unit || existingAxis || allNull) {
        return;
      }

      axes.push(
        <YAxis
          key={tsMetric.unit}
          yAxisId={tsMetric.unit}
          unit={tsMetric.unit}
          tick={<CustomTick unit={tsMetric.unit} fontSize={10} colorZero={false} />}
          tickLine={{ stroke: "#E0E0E0", strokeWidth: 1 }}
          axisLine={{ stroke: "#E0E0E0", strokeWidth: 1 }}
          style={{
            fontStyle: "normal",
            fontWeight: "500",
            fill: "black",
            color: "black",
          }}
          orientation="left"
          width={singleYAxisWidth}
          // [0,0] range always renders zero on the y-axis.  If values fall outside this range recharts still renders them.  Using this behavior avoids complex domain calculations.
          domain={includeZeroYAxis ? [0, 0] : ["dataMin", "dataMax"]}
          type="number"
        />,
      );
      usedUnits.push(tsMetric.unit);
    });
    return axes;
  };

  /**Get YAxisMargin for axis synchronization in AlarmTimeline */
  const getYAxisMargin = () => {
    const axes = getYAxes();
    const axisMargin = axes.length * singleYAxisWidth;

    return axisMargin;
  };

  const getSeriesName = (uuid) => {
    const result = findSeries(uuid, site);
    const tsAssembly = findAssembly(uuid, site);
    return tsAssembly.name + ":" + result.tsMetric.name;
  };

  const getSeriesUnits = (uuid) => {
    const result = findSeries(uuid, site);
    return result.tsMetric.unit?.trim();
  };

  const getLines = () => {
    let lines = [];

    timeseriesDataKeys.forEach((key, index) => {
      const series_response = findSeries(key, site);
      const series = series_response.tsMetric;
      const axisId = axisUnits.find((axis) => axis.unit === series.unit)?.id || 0;

      if (seriesIsAllNull(timeSeries, key)) {
        return;
      }

      lines.push(
        <Line
          yAxisId={axisId}
          isAnimationActive={animation}
          onAnimationEnd={handleAnimationEnd}
          key={series.uuid}
          type={useGraphInterpolation ? "monotone" : "linear"}
          dataKey={key}
          stroke={theme.recharts.valueColors[index]}
          strokeWidth={2}
          connectNulls={useGraphInterpolation ? true : false}
          style={{ zIndex: 20 }}
          dot={false}
          time
          animationDuration={100}
        />,
      );
    });

    return lines;
  };

  const getGrid = () => {
    const showGrid = getYAxes().length <= 1;
    if (!showGrid) {
      return <></>;
    }

    return (
      <CartesianGrid strokeDasharray="4 4" stroke="#808080" strokeOpacity="10%" vertical={false} />
    );
  };

  const leftMargin = 20;
  const rightMargin = 20;

  const populateGraphFavoritesPayload = (graphTitle) => {
    const timeRangeOffsets = graphRangeToFavoriteOffset(displayRange, timezone);
    const newGraphPayload = {
      interpolation: useGraphInterpolation,
      lock_legend: lockLegend,
      offset_1: timeRangeOffsets.startOffset,
      offset_2: timeRangeOffsets.stopOffset,
      sites: [
        {
          metrics: metricUuids,
          uuid: site.uuid,
        },
      ],
      title: graphTitle,
      y_axis_adjust: includeZeroYAxis,
    };

    return newGraphPayload;
  };

  const saveNewGraph = async (graphTitle) => {
    const newGraphPayload = populateGraphFavoritesPayload(graphTitle);
    try {
      await createGraphFavoriteMutation.mutateAsync(newGraphPayload);
      enqueueSnackbar("Graph saved.", { autoHideDuration: 5000 });
    } catch (error) {
      enqueueSnackbar("Graph unable to save. Please try again in a few minutes.", {
        autoHideDuration: 5000,
      });
    }
  };

  const saveGraphChanges = async () => {
    let editedGraphPayload = populateGraphFavoritesPayload(editableGraphTitle);
    editedGraphPayload.uuid = graphFavorite.uuid;
    try {
      await editGraphFavoriteMutation.mutateAsync(editedGraphPayload);
      enqueueSnackbar("Changes saved.", { autoHideDuration: 5000 });
      setEditModeEnabled(false);
    } catch (error) {
      enqueueSnackbar("Graph unable to save changes. Please try again in a few minutes.", {
        autoHideDuration: 5000,
      });
    }
  };

  const deleteGraph = async () => {
    try {
      await deleteGraphFavoriteMutation.mutateAsync(graphFavorite.uuid);
      handleGraphClose();
      enqueueSnackbar("Graph deleted.", { autoHideDuration: 5000 });
    } catch (error) {
      enqueueSnackbar("Graph unable to delete. Please try again in a few minutes.", {
        autoHideDuration: 5000,
      });
    }
  };

  const getToggles = () => (
    <Box sx={{ display: "flex", gap: "10px", ml: "10px" }}>
      <GraphToggle
        value={useGraphInterpolation}
        onChange={() => setUseGraphInterpolation(!useGraphInterpolation)}
        title="Interpolation"
        Icon={PolylineOutlinedIcon}
      />
      <GraphToggle
        value={!includeZeroYAxis}
        onChange={() => setIncludeZeroYAxis(!includeZeroYAxis)}
        title="Y-Axis Scaling"
        Icon={ExpandOutlinedIcon}
      />
      <GraphToggle
        value={lockLegend}
        onChange={() => setLockLegend(!lockLegend)}
        title="Lock Legend"
        Icon={ReadMoreOutlinedIcon}
      />
    </Box>
  );

  const getGraph = () => (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ mb: "2rem" }}
      >
        <Box display="flex" flexDirection="row">
          <DateOptionPicker
            site={site}
            updateDisplayRange={(range) => {
              setDisplayRange(GraphUtil.updateDisplayRange(range));
              setZoomParams({ zooming: false, zoomed: false });
            }}
            range={displayRange.range}
          />
          {!missingData && getToggles()}

          {zoomParams.zoomed ? (
            <Button
              variant="outlined"
              size="small"
              color="alt"
              onClick={cancelZoom}
              sx={{ ml: ".75rem" }}
            >
              Cancel Zoom
            </Button>
          ) : null}
        </Box>
        <Box sx={{ display: "flex" }}>
          <GraphDownloadMenu
            displayRange={displayRange}
            site={site}
            timezone={timezone}
            metricsUuids={metricUuids}
          />
          {!isModal && (
            <Button
              variant="contained"
              // size="medium"
              color="secondary"
              onClick={() => setIsSaveGraphModalOpen(true)}
              sx={{ ml: ".5rem", height: "40px" }}
            >
              Save...
            </Button>
          )}
        </Box>
      </Box>
      {/* TODO remove this height and refactor so chart-graph (nth grandparent) element is only thing setting panel height.  Currently this element and chart-graph must be adjusted when changing height.*/}
      <Box display="flex" flexDirection="row" height="400px">
        <Box flexGrow="1" height="100%">
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            alignItems="stretch"
          >
            <>
              {/* Container for graph, does not include Alarm timeline. flex:1 allows graph to fill space timeline does not use without overlapping it */}
              <Box sx={{ width: "100%", flex: 1, position: "relative" }}>
                <ResponsiveContainer width="99%" height="99%">
                  <ComposedChart
                    data={timeSeries}
                    margin={{
                      top: 5,
                      right: rightMargin,
                      left: leftMargin,
                      bottom: 15,
                    }}
                    onMouseDown={(ev) =>
                      GraphUtil.handleZoomStart(ev, zoomParams, setZoomParams, displayRange)
                    }
                    onMouseUp={(ev) => {
                      const newRange = GraphUtil.handleZoomEnd(
                        ev,
                        zoomParams,
                        setZoomParams,
                        timezone,
                      );
                      setPreZoomDisplayRange(displayRange);
                      setDisplayRange(GraphUtil.updateDisplayRange(newRange));
                    }}
                    onMouseMove={(ev) => {
                      GraphUtil.handleZoomUpdate(ev, zoomParams, setZoomParams);
                      setGraphPayload(ev.activePayload);
                    }}
                    onMouseLeave={() => {
                      setGraphPayload(null);
                    }}
                  >
                    <CustomXAxis hide={false} />
                    <Tooltip
                      wrapperStyle={{ zIndex: 10000 }}
                      content={
                        lockLegend ? (
                          <></>
                        ) : (
                          <LineGraphTooltip
                            getBucketAlarms={getBucketAlarms}
                            timezone={timezone}
                            getName={(uuid) => getSeriesName(uuid)}
                            getUnit={(uuid) => getSeriesUnits(uuid)}
                            showAlarms={true}
                            metricUuids={metricUuids}
                            graphPayload={graphPayload}
                            sortMetrics={false}
                          />
                        )
                      }
                      filterNull={false}
                    />
                    {getLines()}
                    {getYAxes()}
                    {getGrid()}
                    {zoomParams?.zooming ? (
                      <ReferenceArea
                        yAxisId={axisUnits[0].id}
                        x1={zoomParams.startIndex}
                        x2={zoomParams.stopIndex}
                      />
                    ) : null}
                  </ComposedChart>
                </ResponsiveContainer>
              </Box>

              <AlarmTimeline
                timeSeries={timeSeries}
                displayRange={displayRange}
                bigBuckets={bigBuckets}
                alarmHistoryData={alarmHistoryData}
                getBucketAlarms={getBucketAlarms}
                leftMargin={getYAxisMargin() + leftMargin}
                rightMargin={rightMargin}
              />
            </>
          </Box>
        </Box>
        {lockLegend && (
          <Box>
            <LineGraphTooltip
              getBucketAlarms={getBucketAlarms}
              timezone={timezone}
              getName={(uuid) => getSeriesName(uuid)}
              getUnit={(uuid) => getSeriesUnits(uuid)}
              showAlarms={true}
              metricUuids={metricUuids}
              isStaticLegend={true}
              graphPayload={graphPayload}
              sortMetrics={false}
            />
          </Box>
        )}
      </Box>
    </>
  );

  const missingData =
    !metricUuids || metricUuids.length === 0 || timeSeries.length === 0 || !metricsTimeseriesData;
  const hasError = error || isRefetchError;

  const getGraphContents = () => {
    if (isFetching) {
      return <Loading />;
    }
    if (missingData) {
      return (
        <Box sx={styles.missingDataContainer}>
          <Typography variant="subtitle2">
            Please select a metric. If you have selected a metric, it may have no data
          </Typography>
        </Box>
      );
    }
    if (hasError) {
      return <DataComponentError />;
    }
    return getGraph();
  };

  const getSaveDeleteCloseButtons = () => (
    <>
      <IconButton color="secondary" onClick={() => setEditModeEnabled(true)}>
        <ModeEditOutlineOutlinedIcon fontSize="inherit" sx={{ fontSize: "24px" }} />
      </IconButton>
      <IconButton color="secondary" onClick={() => setIsDeleteGraphModalOpen(true)}>
        <DeleteOutlineIcon fontSize="inherit" sx={{ fontSize: "24px" }} />
      </IconButton>
      <IconButton onClick={handleGraphClose}>
        <CloseOutlinedIcon fontSize="inherit" sx={{ fontSize: "24px" }} />
      </IconButton>
    </>
  );

  const getCancelSaveChangesButtons = () => (
    <>
      <Button
        variant="outlined"
        color="inherit"
        onClick={handleGraphEditCancel}
        sx={{ ml: ".5rem", height: "40px", color: "rgba(0, 0, 0, 0.56)" }}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={saveGraphChanges}
        sx={{ ml: ".5rem", height: "40px" }}
      >
        Save Changes
      </Button>
    </>
  );

  const getEditableTitleInput = () => (
    <TextField
      id="outlined-basic"
      variant="outlined"
      sx={{
        width: "18.7rem",
        "& .MuiInputBase-input": {
          fontSize: "13px",
        },
      }}
      value={editableGraphTitle}
      onChange={(e) => setEditableGraphTitle(e.target.value)}
      inputProps={{ maxLength: 24 }}
      size="small"
    />
  );

  return (
    <Box
      id="chart-graph"
      sx={{ position: "relative", display: "flex", flexDirection: "column", height: "530px" }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ mb: "1.5em" }}>
          {isModal && (
            <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {site.site_name}
            </Typography>
          )}
          <Typography variant="body2" color="secondary">
            {!editModeEnabled ? editableGraphTitle || "Grapher" : getEditableTitleInput()}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
          {isModal && (
            <Box>
              {editModeEnabled ? getCancelSaveChangesButtons() : getSaveDeleteCloseButtons()}
            </Box>
          )}
        </Box>
      </Box>
      {getGraphContents()}
      <SaveGraphFavoriteModal
        saveGraph={saveNewGraph}
        isOpen={isSaveGraphModalOpen}
        setIsOpen={setIsSaveGraphModalOpen}
        isLoading={createGraphFavoriteMutation.isLoading}
      />
      <GraphFavoritesDeleteModal
        deleteGraph={deleteGraph}
        isOpen={isDeleteGraphModalOpen}
        setIsOpen={setIsDeleteGraphModalOpen}
      />
    </Box>
  );
};

TimeSeriesLineGraph.propTypes = {
  site: PropTypes.object.isRequired,
  metricUuids: PropTypes.array,
  isModal: PropTypes.bool,
  title: PropTypes.string,
  graphFavorite: PropTypes.object,
  handleCloseModal: PropTypes.func,
  editModeEnabled: PropTypes.bool,
  setEditModeEnabled: PropTypes.func,
  setMetricsFromGraphFavorite: PropTypes.func,
};

export default TimeSeriesLineGraph;
