/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2022 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */

import "./style/App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Sidebar from "./components/Shared/Sidebar";
import Topbar from "./components/Shared/Topbar";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import Site from "./components/Site/Site";
import PortfolioMap from "./components/Portfolio/PortfolioMap";
import PortfolioStats from "./components/Portfolio/PortfolioStats";
import AccountSettings from "./components/Account/AccountSettings";
import { Box } from "@mui/material";
import PortfolioSummary from "./components/Portfolio/PortfolioSummary";
import Markets from "./components/Markets/Markets";
import Settings from "./components/Settings/Settings";
import OrganizationPage from "./components/Settings/Organization";
import useUserStore from "./state/UserStore";
import CallbackPage from "./components/Auth/CallbackPage";
import NotAuthorized from "./components/Auth/NotAuthorized";

import { useAuth0 } from "@auth0/auth0-react";
import { registerRequestInterceptor, registerResponseInterceptor } from "./lib/Queries";

function App() {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    getAccessTokenSilently,
    user: authUser,
    logout: authLogout,
    getIdTokenClaims,
    error: authError,
  } = useAuth0();

  /**
   * It invalidates expired user state during app initialization.
   *
   * It checks if the user's exp is expired and, if so,
   * clears the user data by calling the `logout` function from the store.
   *
   * It runs once during the app's lifecycle, ensuring that
   * stale user data is not used when the app starts.
   */
  const invalidateExpiredUser = () => {
    const { user, logout } = useUserStore.getState();
    if (user?.exp && user.exp <= Math.floor(Date.now() / 1000)) {
      logout(); // Clear stale data
    }
  };

  // Invalidate expired state during app initialization
  useEffect(() => {
    invalidateExpiredUser();
  }, []);

  const userStore = useUserStore((state) => state);

  const [isInterceptorSetup, setIsInterceptorSetup] = useState(false);

  const registerInterceptors = async () => {
    registerRequestInterceptor(getAccessTokenSilently);
    registerResponseInterceptor(authLogout);
    setIsInterceptorSetup(true);
  };

  useEffect(() => {
    registerInterceptors();
  }, []);

  const setUser = async () => {
    if (isInterceptorSetup && authUser) {
      const claims = await getIdTokenClaims();
      userStore.setUser(claims);
    }
  };

  useEffect(() => {
    setUser();
  }, [isInterceptorSetup, authUser]);

  /*
  NTM - when the root app component loads, start a regular check 
  of the token expiration time.  When it expires, log the user out.
  */

  const logoutIfExpired = () => {
    if (userStore?.user?.auto_logout_disabled) {
      return;
    }

    let timeLeft = userStore.user?.exp - Math.floor(Date.now() / 1000);
    // log the user out if we can't calculate the token expiry
    // or it has passed.
    if (!isNaN(timeLeft) && timeLeft <= 0) {
      userStore.logout();
      authLogout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  useEffect(() => {
    setInterval(logoutIfExpired, 15000);
  }, []);

  const handleAuthError = () => {
    if (!authError) {
      return;
    }
    console.error("Auth Error", authError);
    const redirectLocation = window.location.origin + "/not-authorized";
    authLogout({ logoutParams: { returnTo: redirectLocation } });
  };

  useEffect(handleAuthError, [authError]);

  const setupSegmentTracking = () => {
    if (process.env.REACT_APP_SEGMENT) {
      const head = document.querySelector("head");
      const script = document.createElement("script");
      script.innerHTML = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.REACT_APP_SEGMENT}";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("${process.env.REACT_APP_SEGMENT}");
  analytics.page();
  }}();`;
      head.appendChild(script);
    }
  };

  useEffect(() => {
    setupSegmentTracking();
  }, []);

  const identifyUserInSegment = () => {
    if (userStore.user) {
      // Now that we have a valid user, we can identify them in Segment
      if (window.analytics) {
        window.analytics.identify(userStore.user.email_address, {
          name: userStore.user.name,
          app: "NOC",
        });
      }
    }
  };

  useEffect(() => {
    identifyUserInSegment();
  }, [userStore.user]);

  const getAdminRoutes = () => {
    return (
      <>
        <Route path="/settings" element={<Settings key="organization" />} />
        <Route
          path="/organization/:organization_name"
          element={<OrganizationPage key="organization/settings" />}
        />
      </>
    );
  };

  const getLoggedInRoutes = () => {
    if (!isInterceptorSetup) {
      return null;
    }

    return (
      <Routes>
        <Route exact path="/" element={<Dashboard key="dashboard" />} />
        <Route exact path="/login" element={<Navigate to="/" />} />
        <Route exact path="uplogin" element={<Navigate to="/" />} />
        <Route path="/dashboard/:site?/:alarm?" element={<Dashboard key="dashboard" />} />
        <Route path="/site/:sitename" element={<Site key="site" />} />
        <Route path="/portfolio" element={<PortfolioSummary key="porfolio" />} />
        <Route path="/portfolio/map" element={<PortfolioMap key="portfolio/map" />} />
        <Route path="/portfolio/stats" element={<PortfolioStats key="portfolio/stats" />} />
        <Route path="/account" element={<AccountSettings key="settings" />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/" element={<Dashboard key="dashboard" />} />
        {userStore.isAdmin() && getAdminRoutes()}
      </Routes>
    );
  };

  const getLoggedOutRoutes = () => (
    <Routes>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/not-authorized" element={<NotAuthorized />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );

  if (isAuthLoading) {
    return null;
  }

  return (
    <Box>
      {isAuthenticated ? (
        <>
          <Sidebar />
          <Topbar />
          {getLoggedInRoutes()}
        </>
      ) : (
        getLoggedOutRoutes()
      )}
    </Box>
  );
}

export default App;
