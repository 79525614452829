/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
const data = {
  statusList: ["Pre-Commissioning", "Commissioning", "Operating"],
  contractTypesList: ["MSA", "AMA", "PPA", "VDER", "NEM", "ESA", "CREF"],
  dealStructuresList: ["Financed", "Capital Sale"],
  billSavingsProvidersList: ["Schneider", "CPower", "Energy Toolbase", "NONE"],
  gridServicesProvidersList: ["CPower", "Energy Toolbase", "NONE"],
};
export default data;
