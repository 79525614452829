/*
 * Copyright © Scale Microgrid Solutions Operating, LLC [2023].
 * All rights reserved.
 *
 * SPDX-FileCopyrightText: ©2023 Scale Microgrid Solutions Operating, LLC <legal@scalemicrogrids.com>
 */
import React from "react";

import { useQuery } from "@tanstack/react-query";
import { getSiteEnergyProductionQuery } from "../../../lib/Queries";
import { GraphRange, SiteMetadata } from "../../../lib/Types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

interface Props {
  site: SiteMetadata;
  displayRange: GraphRange;
}

const DropdownEnergy = ({ site, displayRange }: Props) => {
  const {
    isLoading,
    error,
    data: energyData,
  } = useQuery({
    ...getSiteEnergyProductionQuery(
      site.uuid,
      displayRange.start,
      displayRange.stop,
      displayRange.interval.toString(),
    ),
  });

  const formatEnergy = (energy: number): string => {
    if (energy === null) return "--";

    let unit = "KWh";
    if (energy > 10000) {
      energy = energy / 1000;
      unit = "MWh";
    }
    const formatedValue = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(energy);
    return `${formatedValue} ${unit}`;
  };

  const styles = {
    noSelectHoverMenuProps: {
      PaperProps: {
        sx: {
          "& .MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: "transparent",
              cursor: "default",
            },
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&:active": {
              backgroundColor: "transparent",
            },
            fontSize: "13px",
          },
        },
      },
    },
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel>Energy Production</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={"Energy Production"}
        renderValue={(value) =>
          // @ts-ignore
          isLoading ? "Loading..." : "Total: " + formatEnergy(energyData?.site_energy)
        }
        MenuProps={styles.noSelectHoverMenuProps}
        label="Energy Production"
        sx={{ width: "13rem" }}
      >
        {energyData?.der_groups.map((assembly, index) => {
          return (
            <MenuItem key={index}>
              {error
                ? "Error..."
                : isLoading
                  ? "Loading..."
                  : `${assembly.group_assemblies}: ${formatEnergy(assembly.energy)}`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownEnergy;
