import { GraphFavoriteData, GraphRange } from "./Types";
import GraphUtil from "./GraphUtil";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const getStartOfNextDay = (timezone: string): Dayjs => {
  const currentTime = dayjs.utc().tz(timezone);
  const startOfCurrentDay = currentTime.hour(0).minute(0).second(0).millisecond(0);
  const startOfNextDay = startOfCurrentDay.add(1, "day");
  return startOfNextDay;
};

export const favoriteToGraphRange = (
  graphFavorite: GraphFavoriteData,
  timezone: string,
): GraphRange => {
  // offsets calculated from start of next day / end of current day
  const startOffset = graphFavorite.offset_1;
  const stopOffset = graphFavorite.offset_2;

  const stop = getStartOfNextDay(timezone).subtract(stopOffset, "day");
  const start = getStartOfNextDay(timezone).subtract(startOffset, "day");

  const rangeLabel = getRangeLabel(startOffset, stopOffset);

  let graphRange: GraphRange = {
    range: rangeLabel,
    start: start.format(),
    stop: stop.format(),
    // interval is placeholder, updated below
    interval: 120,
  };

  graphRange = GraphUtil.updateDisplayRange(graphRange);

  return graphRange;
};

export interface OffsetRange {
  startOffset: number;
  stopOffset: number;
}
export const graphRangeToFavoriteOffset = (
  graphRange: GraphRange,
  timezone: string,
): OffsetRange => {
  const startOfNextDay = getStartOfNextDay(timezone);

  const startDate = dayjs(graphRange.start).utc().tz(timezone);
  const stopDate = dayjs(graphRange.stop).utc().tz(timezone);

  const startOffset = startOfNextDay.diff(startDate, "days");
  const stopOffset = startOfNextDay.diff(stopDate, "days");

  return { startOffset: startOffset, stopOffset: stopOffset };
};

const formatDate = (date: Dayjs): string => {
  return date.format("MM/DD/YYYY");
};

export const getRangeLabel = (start_offset: number, stop_offset: number): string => {
  if (stop_offset === 0 && start_offset === 1) {
    return "today";
  }
  if (stop_offset === 1 && start_offset === 2) {
    return "yesterday";
  }
  if (stop_offset === 0 && start_offset === 7) {
    return "7days";
  }
  if (stop_offset === 0 && start_offset === 28) {
    return "28days";
  }
  if (stop_offset === 0 && start_offset === 90) {
    return "90days";
  }

  return "custom";
};

const rangeLabelDisplayMapping = {
  today: "Today",
  yesterday: "Yesterday",
  "7days": "Last 7 Days",
  "28days": "Last 28 Days",
  "90days": "Last 90 Days",
};

export const formatGraphFavoriteDateRange = (
  graphFavorite: GraphFavoriteData,
  timezone: string,
) => {
  const graphRange = favoriteToGraphRange(graphFavorite, timezone);

  if (graphRange.range in rangeLabelDisplayMapping) {
    // @ts-ignore
    return rangeLabelDisplayMapping[graphRange.range];
  }

  const start = dayjs(graphRange.start).utc().tz(timezone, true);
  const stop = dayjs(graphRange.stop).utc().tz(timezone, true);
  // stop is exclusive, so subtract 1 day for correct display formatting
  const stopPreviousDay = stop.subtract(1, "day");

  return `${formatDate(start)} - ${formatDate(stopPreviousDay)}`;
};
